<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <q-form ref="editForm">
        <!-- 폴더상세 -->
        <c-card title="LBL0010600" class="cardClassDetailForm">
          <template slot="card-button">
            <q-btn-group outline >
              <!-- 저장 -->
              <c-btn 
                v-if="editable" 
                :isSubmit="isSave"
                :url="saveUrl"
                :param="folder"
                :mappingType="mappingType"
                label="LBLSAVE" 
                icon="save"
                @beforeAction="saveFolder"
                @btnCallback="saveCallback" />
            </q-btn-group>
          </template>
          <template slot="card-detail">
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <!-- 항목명 -->
              <c-text 
                required 
                :editable="editable"
                label="LBL0010601"
                name="folderName"
                v-model="folder.folderName">
              </c-text>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
              <!-- 상위폴더 -->
              <!-- 상위폴더가 없는 경우 최상위 폴더로 들어가게 됩니다. -->
              <c-text 
                :afterIcon="[
                  { name: 'search', click: true, callbackName: 'searchFolder', color: 'teal' },
                  { name: 'close', click: true, callbackName: 'removeFolder', color: 'red' },
                ]"
                :disabled="true"
                :editable="editable"
                label="LBL0010602"
                placeholder="MSG0010064"
                name="parentFolderName"
                v-model="folder.parentFolderName"
                @searchFolder="() => { isFolderOpen = true }"
                @removeFolder="removeFolder">
              </c-text>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
              <!-- 폴더레벨 -->
              <c-text 
                :disabled="true"
                label="LBL0010603"
                name="folderLevel"
                v-model="folder.folderLevel">
              </c-text>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
              <c-plant 
                required 
                :disabled="disabled"
                :editable="editable" 
                type="edit" 
                name="plantCd" 
                v-model="folder.plantCd" />
            </div>
            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
              <!-- 사용여부 -->
              <c-radio
                :editable="editable"
                :comboItems="useFlagItems"
                label="LBLUSEFLAG"
                name="useFlag"
                v-model="folder.useFlag">
              </c-radio>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
              <!-- 순번 -->
              <c-text 
                :editable="editable"
                type="number"
                label="LBLSORTORDER"
                name="sortOrder"
                v-model="folder.sortOrder">
              </c-text>
            </div>
          </template>
        </c-card>
      </q-form>

      <q-dialog
        ref="folderDialog"
        persistent
        v-model="isFolderOpen">
        <q-card class="folder-card">
          <q-card-section class="row items-center q-pb-none px-2 py-1 bg-orange-custom text-white">
            <div class="text-h6"><!-- 폴더 -->{{ $label('LBL0010604') }}</div>
            <q-space></q-space>
            <q-btn icon="done" flat round dense @click="selectFolder"></q-btn>
            <q-btn icon="close" flat round dense v-close-popup></q-btn>
          </q-card-section>
          <q-item>
            <q-item-section>
              <q-item-label caption>
                <q-chip outline square color="primary" text-color="white">
                  {{folderName}}
                </q-chip>
              </q-item-label>
            </q-item-section>
          </q-item>
          <q-separator></q-separator>

          <q-card-section class="folder-content p-2">
            <!-- 폴더가 없습니다. -->
            <q-tree
              ref="folderTree"
              :nodes="folderTree"
              node-key="opmFolderId"
              label-key="folderName"
              children-key="children"
              :no-nodes-label="$comm.getLangMessage('MSG0010065')"
              no-results-label=""
              :default-expand-all="false"
              selected-color="primary"
              :selected.sync="selectedFolder"
            />
          </q-card-section>
        </q-card>
      </q-dialog>
    </div>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
import arrayToTree from 'array-to-tree';

export default {
  name: 'process-safety-report-folder',

  props: {
    popupParam: {
      type: Object,
      default: () => ({
        opmFolderId: '',
        opmParentFolderId: '',
      }),
    },
  },
  data() {
    return {
      folder: {
        opmFolderId: '',
        opmParentFolderId: '',
        parentFolderName: '',
        folderName: '',
        plantCd: '',
        folderLevel: 1,
        sortOrder: 0,
        deleteFlag: '',
        useFlag: 'Y',
        regUserId: '',
        chgUserId: '',
      },
      useFlagItems: [
        { code: 'Y', codeName: '사용' },
        { code: 'N', codeName: '미사용' },
      ],
      editable: true,
      listUrl: '',
      getUrl: '',
      saveUrl: transactionConfig.sop.opm.folder.insert.url,
      folderTree: [],
      selectedFolder: null,
      isSave: false,
      mappingType: 'POST',
      isFolderOpen: false,
      folderName: '폴더를 선택하세요.',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  watch: {
    selectedFolder() {
      let data = this.$refs['folderTree'].getNodeByKey(this.selectedFolder);
      this.folderName = data.folderName;
    },
  },
  computed: {
    disabled() {
      return this.popupParam.opmFolderId ? true : false;
    }
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.sop.opm.folder.list.url;
      this.getUrl = selectConfig.sop.opm.folder.get.url;
      this.insertUrl = transactionConfig.sop.opm.folder.insert.url
      this.updateUrl = transactionConfig.sop.opm.folder.update.url
      // code setting

      // 하위폴더 생성
      if (this.popupParam.opmParentFolderId) {
        this.folder.opmParentFolderId = this.popupParam.opmParentFolderId;
        this.getDetail();
      } else {
        this.getDetail();
      }
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.folderTree = this.convertTree(_result.data);
      },);
    },
    getDetail() {
      if (this.popupParam.opmFolderId) {
        this.$http.url = this.$format(this.getUrl, this.popupParam.opmFolderId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.folder = _result.data
        },);
      } else if (this.popupParam.opmParentFolderId) {
        this.$http.url = this.$format(this.getUrl, this.popupParam.opmParentFolderId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.folder.opmParentFolderId = _result.data.opmFolderId
          this.folder.folderLevel = Number(_result.data.folderLevel) + 1
          this.folder.parentFolderName = _result.data.folderName
          this.folder.sortOrder = Number(_result.data.childrenCount) + 1
        },);
      }
    },
    /* eslint-disable no-unused-vars */ 
    convertTree(_treeBases) {
      if (!_treeBases || _treeBases.length <= 0) return null;
      var tree = arrayToTree(_treeBases, {
        parentProperty: 'opmParentFolderId',
        customID: 'opmFolderId',
      });
      return tree;
    },
    selectFolder() {
      if (this.selectedFolder) {
        let data = this.$refs['folderTree'].getNodeByKey(this.selectedFolder);
        this.folder.parentFolderName = data.folderName;
        this.folder.opmParentFolderId = data.opmFolderId;
        this.folder.folderLevel = Number(data.folderLevel) + 1;
        this.folder.sortOrder = data.hasOwnProperty('children')
        ? data.children.length + 1
        : 1;
        this.$refs['folderDialog'].hide();
      } else {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSG0010066', // 폴더를 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      }
    },
    removeFolder() {
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',
        message: 'MSG0010067',  // 상위폴더가 없는 경우 최상위 폴더로 들어가게 됩니다.\n\r진행하시겠습니까?
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.folder.opmParentFolderId = '';
          this.folder.parentFolderName = '';
          this.folder.folderLevel = 1;
          this.folder.sortOrder = 1;
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    saveFolder() {
      if (this.popupParam.opmFolderId) {
        this.saveUrl = this.insertUrl;
        this.mappingType = 'PUT';
      } else {
        this.saveUrl = this.updateUrl;
        this.mappingType = 'POST';
      }

      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM', // 확인
            message: 'MSGSAVE', // 저장하시겠습니까?
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.folder.regUserId = this.$store.getters.user.userId
              this.folder.chgUserId = this.$store.getters.user.userId

              this.isSave = !this.isSave
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(result) {
      this.popupParam.opmFolderId = result.data
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getPreStartupCheckItem();
    },
  }
};
</script>
<style lang="sass">
.folder-card
  width: 100%
  max-width: 450px !important
  height: 100%
  max-height: 500px !important
  overflow-y: hidden !important
.folder-content
  height: 100%
  max-height: 400px !important
  overflow-y: auto
.folder-card
  .bg-dialogheader
    height: 40px
    .q-btn
      .q-btn__wrapper
        padding: 0px !important
  .text-h6
    font-size: 1.2em
    line-height: 1.2em
    font-weight: 700
.customfolder
  .q-field__native
    padding-left:3px
    font-size: 0.95em !important
  .q-field__label
    margin-left: 5px
  .q-field__native::placeholder
    font-size: 1em !important
  .material-icons
    font-size: 0.8rem
  .folderCloseIcon
    display: none
.customfolder:hover
  .folderCloseIcon
    display: block
.customfolder.q-field--dense .q-field__control, .customfolder.q-field--dense .q-field__marginal
  height: 33px !important
  min-height: 33px !important

.customfolder.q-field--filled .q-field__control
  border: 1px solid rgba(0,0,0,0.15) !important
  padding: 0px 8px 0px 2px

.customfolder.q-field--filled.q-field--readonly .q-field__control:before
  opacity: 0
</style>